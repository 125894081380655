<template>
    <OModal ref="modalRef" @show="frequencyEditorRef.syncData()">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("Generate Planned Series Items / Subscope") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <FrequencyEditor ref="frequencyEditorRef" v-bind="$props" bodyClass="modal-body bg-light" footerClass="modal-footer d-flex" @generateItems="emit('generateItems')" @save="emit('save')" />
            </div>            
        </div>        
    </OModal>
</template>

<script setup lang="ts">
import { OModal } from "o365-ui-components";
import { ref } from "vue";
import FrequencyEditor from "./FrequencyEditor.FrequencyEditor.vue";
import { DataItemModel } from 'o365-dataobject';

const emit = defineEmits(['generateItems', 'save']);

/* Props copied from FrequencyEditor.FrequencyEditor.vue component */
const props = withDefaults(defineProps<{
    row: DataItemModel,
    startDateFieldName: string,
    endDateFieldName: string,
    autoGenerate?: boolean,
    targetProcessFieldName?: string,
    targetProcessIdFieldName?: string,
    numberOfItems?: number,
    isGeneratingConnectedItems?: boolean,
    footerClass?: string,
    bodyClass?: string
}>(), {
    autoGenerate: undefined,    
});

const modalRef = ref();
const frequencyEditorRef = ref();

function show() {
    modalRef.value.show();
}

defineExpose({ show });
</script>